<template>
<div>
  <div class="container-fluid">
    <PageTitle />

    <template v-if="isList">
      <card-list
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"
      >
      <template #ml_nilai_kerugian="data">
        <span>Rp. </span>{{data.scope.value.format("id-ID")}}
      </template>
      <template #count="data">
        <ul>
          <li v-for="(value, index) in Object.keys(data.scope.value)" :key="index">
            {{ value }} : {{ data.scope.value[value] }}
          </li>
        </ul>
      </template>
      <template #mo_name="data">
        <small class="text-info text-semibold">
          {{data.scope.item.mo_code}} - {{ data.scope.item.mbl_name.toUpperCase() }} - {{data.scope.value}}}}
        </small>
        <small class="ml-1 mr-1 text-semibold"> / </small>
        <small class="text-semibold">
          {{data.scope.item.muk_code}} - {{ data.scope.item.muk_level.toUpperCase() }} - {{data.scope.item.muk_description}}
        </small>
      </template>
      <template #ml_tgl_terjadi="data">
        {{data.scope.value | moment('DD-MM-YYYY')}}
      </template>
      <template #mo_level="data">
        {{data.scope.value.charAt(0).toUpperCase() + data.scope.value.slice(1)}}
      </template>
      <template #filters>
        <!-- <template v-if="mrBranchLevel.length"> -->
          <b-col md=2 class="mb-3">
            <VSelect 
              v-model="filter.year" 
              placeholder="-- Semua Tahun --"
              :options="tahun" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
          <b-col md=2 class="mb-3">
            <VSelect 
              v-model="filter.month" 
              placeholder="-- Semua Bulan --"
              :options="bulan" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
        <!-- </template> -->
        <template v-if="mrBranchLevel.length">
          <b-col md=2 class="mb-3">
            <VSelect 
              v-model="filter.branch_level" 
              placeholder="-- Semua Level --"
              :options="mrBranchLevel" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
        </template>
        <template v-if="mrKantor.length">
          <b-col md=4 class="mb-3">
            <VSelect 
              v-model="filter.kantor" 
              placeholder="-- Semua Kantor --"
              :options="mrKantor" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
        </template>
        <template v-if="mrUnitKerja.length">
          <b-col md=4 class="mb-3">
            <VSelect 
              v-model="filter.unit_kerja" 
              placeholder="-- Semua Unit Kerja --"
              :options="mrUnitKerja" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
        </template>
      </template>
      <template #dokumen="data">
        <ImagePreview 
          :src="uploader(data.scope.item.ml_doc_evidence)"
          type="blank"
          v-if="data.scope.item.ml_doc_evidence"
        >
          <a :href="uploader(data.scope.item.ml_doc_evidence)" target="_blank" rel="noopener noreferrer" class="txt_link_download"> 
            <i class="far fa-file-pdf"></i> Dokumen
          </a>
        </ImagePreview>
        <p v-else>-</p>
      </template>
      <template #aksi="data">
        <b-dropdown no-flip>
          <template #button-content>
            <i class="icon-menu"></i>
          </template>
          <template v-if="!moduleRole('ViewOnly')">
            <b-dropdown-item :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
              Ubah Data
            </b-dropdown-item>
            <b-dropdown-item @click="doDelete(data.scope.index, data.scope.item)">Hapus</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
          </template>
          <b-dropdown-item 
            @click="doOpenModal(data.scope.item, 'sebab')"
          >
            LED Sebab
          </b-dropdown-item>
          <b-dropdown-item 
            @click="doOpenModal(data.scope.item, 'dampak')"
          >
            LED Dampak
          </b-dropdown-item>
          <b-dropdown-item 
            @click="doOpenModal(data.scope.item, 'kendali')"
          >
            LED Pengendalian
          </b-dropdown-item>
        </b-dropdown>
      </template>

      </card-list>
    </template>
    

    <div class="card" v-else>
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} {{pageTitle}}</h5>
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row class="mb-3">
              <b-col md=6 v-if="isAdminPusat">
                <b-form-group>
                  <template #label>
                    Kantor<span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.ml_office_id" 
                    placeholder="-- Pilih Kantor --"
                    :options="mrKantor" 
                    :reduce="v=>v.value"
                    ref="selectUnitKerja"
                    @input="filterUnitKerja"
                    :disabled="!isAdd"
                  />
                  <VValidate 
                    name="Kantor" 
                    v-model="row.ml_office_id" 
                    :rules="mrValidation.ml_office_id" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    {{ isPusat?'Group':'Unit Kerja' }}<span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.ml_unit_kerja_id" 
                    :placeholder="'-- Semua '+(isPusat?'Group':'Unit Kerja')+' --'"
                    :options="mrUnitKerja" 
                    :reduce="v=>v.value"
                    :disabled="!isAdd"
                  />
                  <VValidate 
                    :name="isPusat?'Group':'Unit Kerja'"
                    v-model="row.ml_unit_kerja_id" 
                    :rules="mrValidation.ml_unit_kerja_id" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-3" v-if="showRisiko || !isAdd">
              <b-col md=6>
              <b-form-group>
                <template #label>
                  Nama Risiko <span class="text-danger mr5">*</span>
                </template>
                <b-overlay
                  :show="isLoadingRisiko"
                >
                  <VSelect 
                    v-model="row.ml_risiko_id" 
                    placeholder="-- Pilih Risiko --"
                    :options="mrRisiko"
                    :reduce="v=>Number(v.value)" 
                    :disabled="!isAdd"
                  />
                </b-overlay>
                <VValidate 
                  name="Risiko" 
                  v-model="row.ml_risiko_id" 
                  :rules="mrValidation.ml_risiko_id" 
                />
              </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Nilai Kerugian 
                  </template>
                  <b-input-group size="md" prepend="Rp" class="w-100">
                    <money
                      v-model="row.ml_nilai_kerugian"
                      v-bind="moneyMasking"
                      class="form-control"
                      style=" border-radius: .25rem; "
                    />  
                  </b-input-group>
                  <VValidate 
                    name="Nilai Kerugian" 
                    v-model="row.ml_nilai_kerugian" 
                    :rules="{integer:1}" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=4>
                <b-form-group>
                  <template #label>
                    Tanggal Terjadi <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-datepicker 
                    v-model="row.ml_tgl_terjadi"
                    locale="id"
                    :date-format-options="{ year: 'numeric', month: 'long', day: '2-digit', weekday: 'short' }"
                    placeholder="Pilih Tanggal"
                  />
                  <VValidate 
                    name="Tanggal Terjadi" 
                    v-model="row.ml_tgl_terjadi" 
                    :rules="mrValidation.ml_tgl_terjadi" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=8>
                <b-form-group>
                  <template #label>
                    Hasil Evaluasi <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-textarea
                    v-model="row.ml_hasil_evaluasi"
                    rows="6"
                    placeholder="Tulis disini..."
                  >
                  </b-form-textarea>
                  <VValidate 
                    name="Hasil Evaluasi" 
                    v-model="row.ml_hasil_evaluasi" 
                    :rules="mrValidation.ml_hasil_evaluasi" 
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col md=4>
                <b-form-group>
                  <template #label>
                    Dokumen Pengendalian <span class="text-danger">*</span>
                  </template>
                  <ImagePreview 
                    :src="uploader(row.ml_doc_evidence)"
                    v-if="row.ml_doc_evidence"
                  >
                    <a href="javascript:;" class="txt_link_download-form w-100 d-flex justify-content-center"> 
                      <i class="far fa-file-pdf"></i>
                    </a>
                  </ImagePreview>
                  <Uploader
                    v-model="row.ml_doc_evidence"
                    :no-help="true"
                    :no-preview="true"
                    type="document" 
                    label="Dokumen"
                  />
                  <sub class="mt-3">Format Dokumen : {{$root.app.uploader.document.rule_type.toUpperCase().split(',').join(', ')}}</sub>
                  <br />
                  <sub>Max Size : {{$root.app.uploader["max_image_size"].bytesToSize()}}</sub>

                  <br />

                  <VValidate 
                    name="Dokumen Pengendalian" 
                    v-model="row.ml_doc_evidence" 
                    :rules="mrValidation.ml_doc_evidence" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button 
                  :disabled="isLoadingRisiko" 
                  type="submit" 
                  class="btn btn-block btn-rounded  btn-info"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>

  <!-- 
    MODAL FORM SEBAB, DAMPAK, KENDALI
  -->
  <b-modal 
    id="RisikoModal" 
    :title="modalTitle"
    :dialog-class="(modalTab=='pilih_risiko'?'modal_xl_custom':'')"
    size="xl"
    ok-variant="info"
    :ok-title="(moduleRole('ViewOnly')?'Tutup':'Simpan')" 
    cancel-title="Batal"
    :ok-only="modalTab!='add_risiko'"
    :hide-header-close="modalTab=='add_risiko'"
    :busy="loadingOverlay"
    no-close-on-backdrop
    no-close-on-esc
    @ok="doOKModal"
    @cancel="doCancelModal"
    @close="doCancelModal"
  >
    <b-overlay :show="loadingOverlay" rounded="sm">
      <template v-if="Object.keys(modal).length">
        <template v-if="modalTab=='add_risiko'">
          <ValidationObserver v-slot="{ handleSubmit }" ref="VForm2">
            <form @submit.prevent="handleSubmit(doAddRisiko)">
              <b-form-group
                label="Kategori *"
              >
                <VSelect 
                  v-model="input.category" 
                  placeholder="-- Pilih Satu --"
                  :options="getMrCategory(tabRisiko)" 
                  :reduce="v=>v.value" 
                />
                <VValidate 
                  name="Kategori" 
                  :value="input.category||''"
                  rules="required" 
                />
              </b-form-group>
              <b-form-group
                label="Deskripsi *"
              >
                <b-input
                  v-model="input.name"
                />
                <VValidate 
                  name="Deskripsi" 
                  :value="input.name||''"
                  rules="required" 
                />
              </b-form-group>
              <b-form-group
                v-if="tabRisiko=='kendali'"
                label="Terkait *"
              >
                <VSelect 
                  v-model="input.terkait" 
                  placeholder="-- Pilih Satu --"
                  :options="mrTerkait" 
                  :reduce="v=>v.value" 
                />
                <VValidate 
                  name="Terkait" 
                  :value="input.terkait||''"
                  rules="required" 
                />
              </b-form-group>
            </form>
          </ValidationObserver>
        </template>
        <template v-else-if="modalTab=='pustaka_risiko'">
          <b-card no-body>
            <b-card-header class="bg-white pl-0 pr-0">
              <b-row>
                <b-col md=7>
                  <b-button 
                    v-if="!moduleRole('ViewOnly')"
                    variant="info"
                    @click="modalTab='add_risiko'"
                  >
                    <i class="fa fa-plus-circle"></i> Tambah Data Baru
                  </b-button>
                </b-col>
                <b-col>
                  <b-input-group>
                    <b-input 
                      v-model="MTRSearch.pustaka_risiko"
                      placeholder="Ketik untuk mencari ..."
                    />
                    <b-input-group-append>
                      <b-button
                        variant="info"
                        size="sm"
                        class="btn-icon"
                      >
                        <i class="icon-magnifier"></i>
                      </b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button
                        variant="secondary"
                        size="sm"
                        class="btn-icon"
                        @click="MTRSearch.pustaka_risiko=''"
                      >
                        Reset
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card-header>
            <b-table
              id="my-table4"
              :fields="tabRisiko=='kendali'?MTRField.kendali:MTRField.pustaka_risiko"
              :items="DataSubRisiko(modal[tabRisiko]||[], 'pustaka_risiko', ['Y','N'])"
              :per-page="MTRPerPage.pustaka_risiko"
              :current-page="MTRCurrentPage.pustaka_risiko"
              small
              show-empty
              thead-tr-class="full-color-table full-purple-table"
              bordered
              responsive
            >
              <template #empty>
                <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
              </template>
              <template #cell(number)="data">
                {{ data.index+1 }}
              </template>
              <template #cell(aksi)="data">
                <template v-if="data.item.is_selected=='Y'">
                  <span class="label label-purple">Telah dipilih</span>
                  <button 
                    v-if="!moduleRole('ViewOnly')"
                    type="button" 
                    style="vertical-align:center" 
                    class="btn btn-secondary ml5"
                    @click="doUnSelectRisiko(data,tabRisiko)"
                  >
                    <i class="ti-trash"></i>
                  </button>
                </template>
                <template v-else-if="!moduleRole('ViewOnly')">
                  <button 
                    type="button" 
                    style="vertical-align:center" 
                    class="btn btn-secondary"
                    @click="doSelectRisiko(data,tabRisiko)"
                  >
                    <i class="ti-plus"></i> Pilih Data
                  </button>
                </template>
                <span v-else>-</span>
              </template>
            </b-table>
            <b-pagination
              v-if="DataSubRisiko(modal[tabRisiko]||[], 'pustaka_risiko', ['Y','N']).length >= MTRPerPage.pustaka_risiko"
              v-model="MTRCurrentPage.pustaka_risiko"
              :total-rows="DataSubRisiko(modal[tabRisiko]||[], 'pustaka_risiko', ['Y','N']).length"
              :per-page="MTRPerPage.pustaka_risiko"
              aria-controls="my-table4"
            ></b-pagination>
          </b-card>
        </template>
        <template v-else>
          <div class="wrap_info_preview">
            <div class="table-responsive">
              <table class="table product-overview no-border">
                <tbody>
                  <tr>
                    <td>
                      <div class="wrap_preview_prof">
                        <h3>Kantor</h3>
                        <p>{{modal.mo_detail}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="wrap_preview_prof">
                        <h3>Unit Kerja</h3>
                        <p>{{modal.muk_detail}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="wrap_preview_prof">
                        <h3>Nama Risiko</h3>
                        <p>{{modal.mr_name}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="wrap_preview_prof">
                        <h3>Nilai Kerugian</h3>
                        <p>Rp. {{modal.ml_nilai_kerugian.format("id-ID")}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="wrap_preview_prof">
                        <h3>Tanggal Kejadian</h3>
                        <p>{{modal.ml_tgl_terjadi | moment('DD-MM-YYYY')}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="wrap_preview_prof">
                        <h3>Hasil Evaluasi</h3>
                        <p>{{modal.ml_hasil_evaluasi}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <VTab :justify="true" class="mt-3">
            <template slot="link">
              <VTabLink :step.sync="tabRisiko" option="sebab">Register Risiko Sebab</VTabLink>
              <VTabLink :step.sync="tabRisiko" option="dampak">Register Risiko Dampak</VTabLink>
              <VTabLink :step.sync="tabRisiko" option="kendali">Register Risiko Pengendalian</VTabLink>
            </template>
            <template slot="content">
              <VTabContent :step.sync="tabRisiko" option="sebab">
                <b-card no-body>
                  <b-card-header class="bg-white">
                    <b-row align-h="between">
                      <b-col cols="auto">
                        <b-button-group>
                          <b-button 
                            v-if="!moduleRole('ViewOnly')"
                            variant="warning"
                            @click="modalTab='pustaka_risiko'"
                          >
                            <i class="icon-list"></i> Tambah Dari Pustaka
                          </b-button>
                        </b-button-group>
                      </b-col>
                      <b-col md="5">
                        <b-input-group>
                          <b-input 
                            v-model="MTRSearch.sebab"
                            placeholder="Ketik untuk mencari ..."
                          />
                          <b-input-group-append>
                            <b-button
                              variant="info"
                              size="sm"
                              class="btn-icon"
                            >
                              <i class="icon-magnifier"></i>
                            </b-button>
                          </b-input-group-append>
                          <b-input-group-append>
                            <b-button
                              variant="secondary"
                              size="sm"
                              class="btn-icon"
                              @click="MTRSearch.sebab=''"
                            >
                              Reset
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-table
                    id="my-table3"
                    :fields="MTRField.sebab"
                    :items="DataSubRisiko(modal.sebab||[], 'sebab')"
                    :per-page="MTRPerPage.sebab"
                    :current-page="MTRCurrentPage.sebab"
                    small
                    show-empty
                    thead-tr-class="full-color-table full-purple-table"
                    bordered
                    responsive
                  >
                    <template #empty>
                      <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                    </template>
                    <template #cell(number)="data">
                      {{ data.index+1 }}
                    </template>
                    <template #cell(aksi)="data">
                      <button 
                        v-if="!moduleRole('ViewOnly')"
                        type="button" 
                        style="vertical-align:center" 
                        class="btn btn-secondary"
                        @click="doUnSelectRisiko(data,'sebab')"
                      >
                        <i class="ti-trash"></i>
                      </button>
                      <span v-else>-</span>
                    </template>
                  </b-table>
                  <template #footer>
                    <b-row align-h="between">
                      <b-col md=2>
                        <b-input-group 
                          v-if="DataSubRisiko(modal.sebab||[], 'sebab').length > MTRPerPage.sebab"
                        >
                          <template #prepend>
                            <b-input-group-text>Show</b-input-group-text>
                          </template>
                          <b-form-select
                            class="w-auto mx-50"
                            v-model="MTRPerPage.sebab"
                            :options="Config.dataShownOptions"
                          />
                        </b-input-group>
                      </b-col>
                      <b-col cols="auto">
                        <b-pagination
                          v-if="DataSubRisiko(modal.sebab||[], 'sebab').length >= MTRPerPage.sebab"
                          v-model="MTRCurrentPage.sebab"
                          :total-rows="DataSubRisiko(modal.sebab||[], 'sebab').length"
                          :per-page="MTRPerPage.sebab"
                          aria-controls="my-table3"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </VTabContent>
              <VTabContent :step.sync="tabRisiko" option="dampak">
                <b-card no-body>
                  <b-card-header class="bg-white">
                    <b-row align-h="between">
                      <b-col cols="auto">
                        <b-button-group>
                          <b-button 
                            v-if="!moduleRole('ViewOnly')"
                            variant="warning"
                            @click="modalTab='pustaka_risiko'"
                          >
                            <i class="icon-list"></i> Tambah Dari Pustaka
                          </b-button>
                        </b-button-group>
                      </b-col>
                      <b-col md="5">
                        <b-input-group>
                          <b-input 
                            v-model="MTRSearch.dampak"
                            placeholder="Ketik untuk mencari ..."
                          />
                          <b-input-group-append>
                            <b-button
                              variant="info"
                              size="sm"
                              class="btn-icon"
                            >
                              <i class="icon-magnifier"></i>
                            </b-button>
                          </b-input-group-append>
                          <b-input-group-append>
                            <b-button
                              variant="secondary"
                              size="sm"
                              class="btn-icon"
                              @click="MTRSearch.dampak=''"
                            >
                              Reset
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-table
                    id="my-table3B"
                    :fields="MTRField.dampak"
                    :items="DataSubRisiko(modal.dampak||[], 'dampak')"
                    :per-page="MTRPerPage.dampak"
                    :current-page="MTRCurrentPage.dampak"
                    small
                    show-empty
                    thead-tr-class="full-color-table full-purple-table"
                    bordered
                    responsive=""
                  >
                    <template #empty>
                      <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                    </template>
                    <template #cell(number)="data">
                      {{ data.index+1 }}
                    </template>
                    <template #cell(aksi)="data">
                      <button 
                        v-if="!moduleRole('ViewOnly')"
                        type="button" 
                        style="vertical-align:center" 
                        class="btn btn-secondary"
                        @click="doUnSelectRisiko(data,'dampak')"
                      >
                        <i class="ti-trash"></i>
                      </button>
                      <span v-else>-</span>
                    </template>
                  </b-table>
                  <template #footer>
                    <b-row align-h="between">
                      <b-col md="2">
                        <b-input-group 
                          v-if="DataSubRisiko(modal.dampak||[], 'dampak').length > MTRPerPage.dampak"
                        >
                          <template #prepend>
                            <b-input-group-text>Show</b-input-group-text>
                          </template>
                          <b-form-select
                            class="w-auto mx-50"
                            v-model="MTRPerPage.dampak"
                            :options="Config.dataShownOptions"
                          />
                        </b-input-group>
                      </b-col>
                      <b-col md="auto">
                        <b-pagination
                          v-if="DataSubRisiko(modal.dampak||[], 'dampak').length >= MTRPerPage.dampak"
                          v-model="MTRCurrentPage.dampak"
                          :total-rows="DataSubRisiko(modal.dampak||[], 'dampak').length"
                          :per-page="MTRPerPage.dampak"
                          aria-controls="my-table3B"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </VTabContent>
              <VTabContent :step.sync="tabRisiko" option="kendali">
                <b-card no-body>
                  <b-card-header class="bg-white">
                    <b-row align-h="between">
                      <b-col cols="auto">
                        <b-button-group>
                          <b-button 
                            v-if="!moduleRole('ViewOnly')"
                            variant="warning"
                            @click="modalTab='pustaka_risiko'"
                          >
                            <i class="icon-list"></i> Tambah Dari Pustaka
                          </b-button>
                        </b-button-group>
                      </b-col>
                      <b-col md="5">
                        <b-input-group>
                          <b-input 
                            v-model="MTRSearch.kendali"
                            placeholder="Ketik untuk mencari ..."
                          />
                          <b-input-group-append>
                            <b-button
                              variant="info"
                              size="sm"
                              class="btn-icon"
                            >
                              <i class="icon-magnifier"></i>
                            </b-button>
                          </b-input-group-append>
                          <b-input-group-append>
                            <b-button
                              variant="secondary"
                              size="sm"
                              class="btn-icon"
                              @click="MTRSearch.kendali=''"
                            >
                              Reset
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-table
                    id="my-table3A"
                    :fields="MTRField.kendali"
                    :items="DataSubRisiko(modal.kendali||[], 'kendali')"
                    :per-page="MTRPerPage.kendali"
                    :current-page="MTRCurrentPage.kendali"
                    small
                    show-empty
                    thead-tr-class="full-color-table full-purple-table"
                    bordered
                    responsive=""
                  >
                    <template #empty>
                      <div class="wrap_no_data" align="center"> <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/> <h3>{{ Config.wording.no_data_listed }}</h3> </div>
                    </template>
                    <template #cell(number)="data">
                      {{ data.index+1 }}
                    </template>
                    <template #cell(terkait)="data">
                      {{ data.value=='sebab'?'kemungkinan':(data.value||'-') }}
                    </template>
                    <template #cell(aksi)="data">
                      <button 
                        v-if="!moduleRole('ViewOnly')"
                        type="button" 
                        style="vertical-align:center" 
                        class="btn btn-secondary"
                        @click="doUnSelectRisiko(data,'kendali')"
                      >
                        <i class="ti-trash"></i>
                      </button>
                      <span v-else>-</span>
                    </template>
                  </b-table>
                  <template #footer>
                    <b-row align-h="between">
                      <b-col md=2>
                        <b-input-group 
                          v-if="DataSubRisiko(modal.kendali||[], 'kendali').length > MTRPerPage.kendali"
                        >
                          <template #prepend>
                            <b-input-group-text>Show</b-input-group-text>
                          </template>
                          <b-form-select
                            class="w-auto mx-50"
                            v-model="MTRPerPage.kendali"
                            :options="Config.dataShownOptions"
                          />
                        </b-input-group>
                      </b-col>
                      <b-col cols="auto">
                        <b-pagination
                          v-if="DataSubRisiko(modal.kendali||[], 'kendali').length >= MTRPerPage.kendali"
                          v-model="MTRCurrentPage.kendali"
                          :total-rows="DataSubRisiko(modal.kendali||[], 'kendali').length"
                          :per-page="MTRPerPage.kendali"
                          aria-controls="my-table3A"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </VTabContent>
            </template>
          </VTab>
        </template>
      </template>
    </b-overlay>
  </b-modal>
</div>  
</template>

<script>
import Gen from '@libs/Gen'
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
import {Money} from 'v-money'

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

let _ = global._
let Swal = global.Swal
export default{
  extends:GlobalVue,
  components:{
    PageTitle, CardList, Money,
    VTab, VTabLink, VTabContent
  },
  data(){
    return {
      idKey:'ml_id',
      statusKey:'ml_is_active',
      fields:[
        { key: 'select', label: "" },
        { key:'number', label:'#' },
        { key:'mo_name', label:'Kantor / Unit Kerja', tdClass:'w_200', is_custom:true},
        { key:'mr_name', label:'Nama Risiko', tdClass:'w_150'},
        { key:'ml_nilai_kerugian', label:'Nilai Kerugian', tdClass: 'w_120', is_custom:true},
        { key: 'ml_tgl_terjadi', label: "Tgl Terjadi", tdClass: 'w_120', is_custom:true},
        //{ key: 'ml_hasil_evaluasi', label: "Hasil Evaluasi", tdClass:'w_150'},
        { key:'count', label:'Jumlah Data', is_custom: true, thClass: 'w_150' },
        //{ key: 'dokumen', label: "Dokumen", is_custom: true, tdClass:'w_120'},
        { key: 'aksi', label: "Aksi", is_custom: true, tdClass: 'width_120', thClass: 'width_120'}
      ],
      isLoadingRisiko: false,
      moneyMasking: {
        decimal: ',', thousands: '.', 
        prefix: '', suffix: '', 
        precision:0, masked: false
      },
      
      /** MASTER */
      mrKantor:[],
      mrCategory:[],
      mrUnitKerja:[],
      mrBranchLevel: [],
      mrTerkait: [],
      mrRisiko:[],
      mrUnitKerjaTemp: [],
      isAdminPusat: null,
      risiko: [],
      status: [
        {id : 1, name: "Active"},
        {id: 2, name: "Inactive"}
      ],

      /** POPUP DATA */
      input:{},
      modal:{},
      modalTab:'pilih_risiko',
      tabRisiko:'',
      MTRPerPage:{
        sebab:10,
        dampak:10,
        kendali:10,
        pustaka_risiko:10,
      },
      MTRSearch:{
        sebab:"",
        dampak:"",
        kendali:"",
        pustaka_risiko:"",
      },
      MTRCurrentPage:{
        sebab:1,
        dampak:1,
        kendali:1,
        pustaka_risiko:1,
      },
      MTRField:{
        sebab:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          'aksi',
        ],
        dampak:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          'aksi',
        ],
        kendali:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          { key:'terkait' },
          'aksi',
        ],
        pustaka_risiko:[
          { key:'number', label:'#' },
          { key:'kategori' },
          { key:'nama', label:'Deskripsi' },
          {key:'aksi', tdClass:'w_100'},
        ]
      },
      tahun: [],
      bulan: [
        {value: 1, label: "Januari"},
        {value: 2, label: "Februari"},
        {value: 3, label: "Maret"},
        {value: 4, label: "April"},
        {value: 5, label: "Mei"},
        {value: 6, label: "Juni"},
        {value: 7, label: "Juli"},
        {value: 8, label: "Agustus"},
        {value: 9, label: "September"},
        {value: 10, label: "Oktober"},
        {value: 11, label: "November"},
        {value: 12, label: "Desember"},
      ]
    }
  },
  computed: {
    showRisiko(){
      return this.row.ml_office_id && this.row.ml_unit_kerja_id
    },
    modalTitle(){
      if(this.modalTab=='pustaka_risiko') return 'Pilih Risiko'
      else if(this.modalTab=='add_risiko') return 'Tambah Risiko '+this.tabRisiko
      return 'Detail Risiko'
    },
    isPusat() {
      return _.filter(this.mrKantor, (v) => {
        return v.value == this.row.ml_office_id && v.level=='pusat';
      }).length;
    },
  },
  methods: {
    /** START:POPUP ACTION */
    doOpenModal(dataVal, tab){
      let data = _.clone(dataVal)
      this.loadingOverlay=true
      Gen.apiRest("/do/" + this.modulePage, {
        data:{
          type:'getSebabDampakKendali',
          id:data[this.idKey],
        }
      }, "POST")
        .then((res)=>{
          this.loadingOverlay=false
          data = Object.assign(data, res.data.data)
          this.modalTab="pilih_risiko"
          this.$set(this,'modal', data)
          this.$set(this,'tabRisiko', tab)
          this.$bvModal.show('RisikoModal')
        })
        .catch(err => {
          this.loadingOverlay=false
          console.log(err)
        })
    },
    doOKModal(bvModalEvt) {
      if(!this.moduleRole('ViewOnly')){
        bvModalEvt.preventDefault()
        if(this.modalTab=='add_risiko'){
          this.doAddRisiko(this.modal[this.tabRisiko], this.tabRisiko)
        }else if(this.modalTab=='pustaka_risiko'){
          this.modalTab='pilih_risiko'
        }else{
          this.doSubmitModal()
        }
      }
    },
    doCancelModal(bvModalEvt) {
      if(['add_risiko','pustaka_risiko'].indexOf(this.modalTab)>-1){
        bvModalEvt.preventDefault()
        this.modalTab='pilih_risiko'
      }else{
        this.$bvModal.hide('RisikoModal')
      }
    },
    doSubmitModal(){
      this.loadingOverlay=true
      Gen.apiRest("/do/" + this.modulePage, {
        data:{
          type:'doSebabDampakKendali',
          id:this.modal[this.idKey],
          sebab:this.DataSubRisiko(this.modal.sebab||[], 'sebab'),
          dampak:this.DataSubRisiko(this.modal.dampak||[], 'dampak'),
          kendali:this.DataSubRisiko(this.modal.kendali||[], 'kendali'),
        }
      }, "POST")
        .then(()=>{
          this.loadingOverlay=false
          Swal.fire({
            icon:'success',
            title:'Berhasil Menyimpan Data!',
            confirmButtonText:'Mengerti'
          }).then((result)=>{
            if (result.value) {
              this.$bvModal.hide('RisikoModal')
            }
            this.apiGet();
          })
        })
        .catch(err => {
          this.loadingOverlay=false
          console.log(err)
          this.apiGet();
        })
    },
    /* DO EVENT */
    doUnSelectRisiko(data, tab){
      let index = _.indexOf(_.map(this.modal[tab], (v)=>{return v.id}), data.item.id)
      // DIRECT UNSELECT
      this.modal[tab][index].is_selected='N'
    },
    doSelectRisiko(data, tab){
      let index = _.indexOf(_.map(this.modal[tab], (v)=>{return v.id}), data.item.id)
      this.modal[tab][index].is_selected='Y'
    },
    doAddRisiko(data, t){
      // ADD TO MASTER
      let tab = t.toString()
      if(tab=='sebab') tab = 'MrRisikoSebab'
      else if(tab=='dampak') tab = 'MrRisikoDampak'
      else if(tab=='kendali') tab = 'MrRisikoKendali'

      let input = this.input
      input['risiko_id']=this.modal.mr_id
      input['is_active']=1
      input = _.mapKeys(this.input, (_, k)=>{
        if(t=='sebab') return 'mrs_'+k
        if(t=='kendali') return 'mrk_'+k
        return 'mrd_'+k
      })

      this.doSubmit(
        "/do/"+tab, 
        Object.assign(input, {
          type:'add'
        }), 
        (type, resp)=>{
          if(type=='success'){
            data.push(resp.data.row); 
            this.$set(this,'input',{});
            this.modalTab='pilih_risiko'
            this.apiGet()
          }else{
            this.showErrorAfterSubmit(type, resp)
          }
        },
        'POST', 'VForm2'
      )
    },
    /* FILTER TAB RISIKO DATA */
    DataSubRisiko(data, tab, isSelected=['Y']){
      let dataSelected = _.filter(
        data,
        (v)=>{
          return isSelected.indexOf(v.is_selected)>-1
        }
      )
      if(!this.MTRSearch[tab] || !tab){
         return dataSelected
      }else{
        let TSearch = (this.MTRSearch[tab]||"").toLowerCase()
        return _.filter(
          dataSelected, 
          (v)=>{
            return ( 
              (v.nama||"").toLowerCase().includes(TSearch)  ||
              (v.kategori||"").toLowerCase().includes(TSearch) ||
              (v.code||"").toLowerCase().includes(TSearch)
            )
          }
        )
      }
    },
    getMrCategory(tab){
      let a = tab.toString()
      if(a=='sebab') a = 'penyebab'
      if(a=='kendali') a = 'pengendalian'
      return _.filter(this.mrCategory, (v)=>{
        return v.group==a
      })
    },
    /** END:POPUP ACTION */

    getRisiko(){
      const {ml_unit_kerja_id} = this.row
      const payload = {
        data: {
          type: "getRisiko",
          ml_unit_kerja_id
        }
      }

      if(this.showRisiko){
        this.isLoadingRisiko = true
        Gen.apiRest("/do/" + this.modulePage, payload, "POST")
          .then(res => {
            this.isLoadingRisiko = false
            this.mrRisiko = res.data.data
          })
          .catch(err => {
            this.isLoadingRisiko=false
            console.log(err)
          })
      } else {
        this.mrRisiko = []
      }

      this.$set(this.row, 'risiko', null)
    },
    filterUnitKerja(){
      let LevelKantor = _.map(_.filter(this.mrKantor, (v) => {
        return v.value == this.row.ml_office_id;
      }), (v)=>{
        return v.level
      })
      LevelKantor = LevelKantor=='pusat'?'divisi':LevelKantor
      let FilterUnit = _.filter(this.mrUnitKerjaTemp, (v)=>{
        return v.level==LevelKantor
      })
      // this.input.filter_unit_kerja = ""
      this.$set(this,'mrUnitKerja',FilterUnit)
      this.$set(this.row,'ml_unit_kerja_id','')
    },
    upperFirstCharacter(text){
      return text.charAt(0).toUpperCase() + text.slice(1)
    }
  },
  mounted(){
    this.apiGet()
    if(this.isAdd) {
      this.row ={}
    }
  },
  watch:{
    $route(){
      this.apiGet()
      if(this.isAdd) {
        this.row ={}
      }
    },
    tabRisiko(){
      this.$set(this,'input',{});
    },
    "row.ml_unit_kerja_id"(){
      this.getRisiko();
    }
  }
}
</script>

<style scoped>
.txt_link_download-form{
  border: none;
  margin-bottom: 1em;
}

.txt_link_download-form i {
  font-size: 24px;
  color: #0097D8;
}
</style>